// Load critical CSS.
require('./src/styles/critical.css');

// Load Service Worker on production only.
exports.registerServiceWorker = () => {
  if (process.env.NODE_ENV !== 'production') {
    return null;
  }

  return true;
};
